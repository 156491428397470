/*Cancel full width for react-toastify on mobile*/
/*@media only screen and (max-width : 480px) {*/
/*  .Toastify__toast-container {*/
/*    width: var(--toastify-toast-width);*/
/*    padding: 4px;*/
/*    left: auto;*/
/*    margin: unset;*/
/*  }*/
/*  .Toastify__toast-container--top-center {*/
/*    top: 0;*/
/*    left: 50%;*/
/*    transform: translateX(-50%);*/
/*  }*/
/*}*/

.Toastify__toast-body {
  white-space: pre-line;
}

/* rc-tooltip */
/* http://react-component.github.io/tooltip/ */
.rc-tooltip-inner {
  font-size: 10px;
  line-height: 1.4;
  min-height: unset;
}
